const awsconfig = {
  aws_project_region: "us-west-1",
  aws_cognito_region: "us-west-1",
  aws_user_pools_id: "us-west-1_M7ZjHghjF",
  aws_user_pools_web_client_id: "75bdcf6kksh9ruks4v9cd1mc0f",
  oauth: {
    domain: "your-domain-prefix.auth.us-west-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  API: {
    endpoints: [
      {
        name: "GlonetApp-V01",
        endpoint: "https://q0z8dbaycd.execute-api.us-west-1.amazonaws.com/dev",
        region: "us-west-1",
      },
    ],
  },
  Auth: {
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};

export default awsconfig;
