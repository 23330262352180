// src/contexts/UserContext.js
import React, { createContext, useState, useContext, useCallback } from "react";
import { Auth } from "aws-amplify";
import log from "../utils/logger";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(Date.now());

  const updateUser = useCallback((newUserData) => {
    setUser((prevUser) => ({ ...prevUser, ...newUserData }));
    setLastUpdate(Date.now());
  }, []);

  const refreshUser = useCallback(async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData.attributes);
      setLastUpdate(Date.now());
    } catch (error) {
      setUser(null);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, refreshUser, lastUpdate }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
