import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-300 py-6 flex flex-col justify-center sm:py-12">
      {/* Test Mode Banner */}
      <div className="sticky top-0 left-0 right-0 bg-yellow-400 text-black py-2 px-4 text-center font-bold z-50">
        Test mode. This site is currently in testing and not yet operational.
      </div>
      
      <div className="relative py-3 sm:max-w-xl sm:mx-auto mt-4">
        <div className="absolute inset-0 bg-gradient-to-r from-teal-400 to-blue-500 shadow-2xl transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-6 py-10 bg-white shadow-2xl sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div className="text-center">
              <img
                src="/MindMachineLearningLOGO.svg"
                className="h-14 sm:h-20 w-auto mx-auto animate-pulse"
                alt="MML Logo"
              />
              <h2 className="mt-8 text-4xl font-extrabold text-gray-900 tracking-tight">
                Glonet
              </h2>
            </div>
            <div className="divide-y divide-gray-200 mt-8">
              <div className="py-8 text-lg leading-7 space-y-6 text-gray-700 sm:text-xl sm:leading-8">
                <p>Flawlessly Matching Attendees for Every Event.</p>
              </div>
            </div>
            <div className="mt-10 space-y-6">
              <div className="space-y-4">
                <Link
                  to="/signin"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-lg text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-transform transform hover:scale-105 duration-200"
                >
                  Log in
                </Link>
                <button
                  disabled
                  className="w-full flex justify-center py-3 px-4 border border-gray-300 rounded-lg shadow-lg text-base font-medium text-gray-400 bg-gray-100 cursor-not-allowed"
                >
                  Sign Up (Disabled)
                </button>
              </div>
              <div className="text-center">
                <button
                  disabled
                  className="text-sm text-gray-400 cursor-not-allowed"
                >
                  Forgot Password? (Disabled)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;