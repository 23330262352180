import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import log from "./utils/logger";

if (process.env.NODE_ENV === "development") {
  log.setLevel("debug");
} else {
  log.setLevel("warn");
}

const isDevelopment = process.env.REACT_APP_ENV === "development";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App isDevelopment={isDevelopment} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
